import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';

import HomePage from './components/HomePage';
import ProjectPage from './components/ProjectPage';
import AboutPage from './components/AboutPage';
import NotFoundPage from './components/NotFoundPage';

import './App.css';

const App = () => {
  return (
    <Router>
      <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Header/>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route exact path="/projects" element={<HomePage />} />
        <Route path="/projects/:slug" element={<ProjectPage />} />
        <Route exact path="/about" element={<AboutPage />} />
        <Route path="/404" element={<NotFoundPage />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
      <Footer/>
      </div>
    </Router>
  );
}

export default App;
