import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useParams, Navigate } from 'react-router-dom';

import api from '../services/api';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css'; 
import { formatDate } from '../utils.js';

const ProjectPage = () => {
  const { slug } = useParams();
  const [project, setProject] = useState(null);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    // Fetch the project based on the slug
    // Use the slug to fetch the specific project data
    const fetchProject = async () => {
      try {
        const response = await api.get(`/api/projects?filters[Slug][$eq]=${slug}&populate=tags`);

        if (response.data.data.length === 1) {
            setProject(response.data.data[0]);
        } else {
            setNotFound(true);
        }

      } catch (error) {
        console.error('Error fetching project:', error);
        setNotFound(true);
      }
    };

    fetchProject();
  }, [slug]);

  if (notFound) {
    return <Navigate to="/404" />;
  }

  return (
    <div>
        <div className="container mt-4 mb-4">
            <div className="row row-cols-1 g-4">
                {project && project.attributes.Content !== null ? (
                    <div>
                        <div className="col d-flex flex-column">
                            <div className="d-flex justify-content-between mb-10">
                              <div className="row col-12">
                                <div className="col-12">
                                  <h1>{project.attributes.Featured === true ? (<span className="project-featured project-page"><i className="bi bi-star-fill"></i></span>) : null}{project.attributes.Title}</h1>
                                </div>

                                <div className="col-md-6 col-sm-12 mb-1">
                                {project.attributes.tags.data.length > 0 ? (
                                  
                                    <div className="tags">
                                      <span className="bold">Tags: </span>

                                      {project.attributes.tags.data.map((tag) => (
                                        <span className="tag badge rounded-pill text-bg-primary">{tag.attributes.Value}</span>
                                      ))}
                                    </div>
                                  
                                ) : null }
                                </div>

                                <div className="col-md-6 col-sm-12">
                                  <div className="published-date-container float-start float-md-end">
                                    <span className="published-label bold">Published:</span>
                                    <span className="published-date">{formatDate(project.attributes.PublishDate)}</span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="project-content flex-grow-1">
                                <ReactMarkdown children={project.attributes.Content} />
                            </div>
                        </div>

                        <div className="mt-5">
                            <p>&lt;/ends&gt;</p>
                        </div>
                    </div>
                ) : (
                    <p>Content Not Found.</p>
                )}
            </div>
        </div>
    </div>
  );
};

export default ProjectPage;
