import React from 'react';

const NotFoundPage = () => {
  return (
    <div>
      <div className="container mt-4">
        <div className="row">
          <h1>404: Not Found</h1>
          <p>Sorry, the page you are looking for does not exist.</p>
          <p>Click <a href="/">here</a> to return to the home page.</p>
        </div>
      </div>
    </div>
  );
}

export default NotFoundPage;
