
export function formatDate (inputDate) {
    const parts = inputDate.split('-');
    if (parts.length !== 3) {
        return 'Invalid date';
    }

    const [year, month, day] = parts;
    return `${day}/${month}/${year}`;
}

export function getImageURL (ImgTile) {
    const defaultImg = '/NoImageFound.png';

    if (!ImgTile 
        || !ImgTile.hasOwnProperty('data') || ImgTile.data === null
        || !ImgTile.data.hasOwnProperty('attributes') || ImgTile.data.attributes === null
        || !ImgTile.data.attributes.hasOwnProperty('formats') || ImgTile.data.attributes.formats === null) {
        return defaultImg;
    }

    const formats = ImgTile.data.attributes.formats;
    const preferredFormats = ['medium', 'small', 'thumbnail'];

    for (var i in preferredFormats) {
        const format = preferredFormats[i];

        if (formats.hasOwnProperty(format)) {
            console.log(formats[format].url);
            return formats[format].url;
        }
    }

    return defaultImg;
}