import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <b>&#123; &#125; Portfolio </b>
          <span className="d-sm-inline-block d-none">| Callum Inglis</span>
        </Link>
        <div className="navbar-nav ml-auto">
          <Link className="nav-link" to="/about">About</Link>
        </div>
      </div>
    </nav>
  );
}

export default Header;
