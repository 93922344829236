import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '../services/api';

import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../App.css'; 
import { formatDate, getImageURL } from '../utils.js';

const HomePage = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await api.get('/api/projects', {
          params: {
            sort: [
              'Featured:desc',
              'PublishDate:desc'
            ],
            populate: 'ImgTile',
          }
        });
        setProjects(response.data.data);
      } catch (error) {
        console.error('Error fetching data from Strapi:', error);
      }
    };

    fetchProjects();
  }, []);

  return (
    <div>
      <div className="hero-banner">
        <div className="container text-center">
          <h1><b>Portfolio</b> by Callum Inglis</h1>
          <p>
            Explore my recent software and hardware projects, as well as my learning and development journey.
          </p>
        </div>
      </div>

        <div className="container mb-4">
            {!projects || projects.length === 0 ? (
                <div>
                  Loading Content...
                </div>
            ) : (
              <div>
                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
                  {projects.map((project) => (
                  <div key={project.id} className="col">
                    <Link to={`/projects/${project.attributes.Slug}`} className="card project-tile">
                      <div className="aspect-ratio-container">
                        <img 
                          src={getImageURL(project.attributes.ImgTile)} 
                          className="card-img-top" 
                          alt={project.attributes.Title} 
                        />
                        <div className="project-title">{project.attributes.Title}</div>
                        {project.attributes.Featured === true ? (
                          <div className="project-featured"><i className="bi bi-star-fill"></i></div>
                        ) : null}
                      </div>
                      <div className="card-body">
                          <h5 className="card-title text-center">{project.attributes.Title}</h5>
                          <p className="card-text text-center">{formatDate(project.attributes.PublishDate)}</p>
                      </div>
                    </Link>
                  </div>
                  ))}
                  </div>
              </div>
            )}
        </div>
    </div>
  );
};

export default HomePage;
