import React from 'react';

const AboutPage = () => {
  return (
    <div>
        <div className="container mt-4 mb-4">
            <div className="row row-cols-1 g-4">
                <h1>About Me</h1>
                <p>Callum is an accomplished and ambitious Software Engineer with a track record delivering projects for clients across sectors, working across operating systems, technologies, and cloud solutions.</p>
                
                <h1>Portfolio As A Website</h1>
                <p>Explore my recent software and hardware projects, as well as my learning and development journey, as documented on this Porfolio-as-a-Website.</p>

                <h4>Get In Touch</h4>
                <p className='contact-links'>
                    <span>Personal - <a target='_blank' rel="noreferrer" href='https://www.calluminglis.com/'>Website</a></span>
                    <span>4oh4 Ltd - <a target='_blank' rel="noreferrer" href='https://www.4oh4.co.uk/'>Website</a></span>
                </p>
            </div>
        </div>
    </div>
  );
}

export default AboutPage;
