import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer mt-auto py-3">
      <div className="container text-center">
        <span>© {currentYear} <Link className="nav-link inline-block" to="https://www.calluminglis.com">Callum Inglis</Link></span>
      </div>
    </footer>
  );
};

export default Footer;
